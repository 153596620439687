import React, {Component} from "react";
import {Row, Col} from "react-bootstrap";
import leganes from "../../img/cbleganes-200x200.png";
import barakaldo from "../../img/logo-ausarta-barakaldo-200x192.png";
import magectias from "../../img/logo-magec-tias.png";
import zamarat from "../../img/logo-cd-zamarat-200x200.png";
import estepona from "../../img/logo-cab-estepona-200x200.png";
import melilla from "../../img/logo-melilla-la-salle.gif";
import logroño from "../../img/logo-unibasket-logroño.png";
import ardoi from "../../img/ardoi-logo-200x126.png";


export default class HomeApp extends Component{
    render(){
        return(
            <div>
                <Row style = {{marginTop: "15" + "px"}}>
                    <Col md = {12}>                        
                        <p>
                            Desde aquí queremos agradecer la inestimable ayuda dada por nuestros colaborados en sus distintas formas. 
                            Como se suele decir, no están todos los que son, pero son todos los que están. A todos ellos,
                            gracias infinitas por su apoyo, ayuda y colaboración en este proyecto.
                        </p>
                        <p>
                            Si estás interesado en el mantenimiento de este proyecto y quieres tener más información sobre cómo hacerlo, puedes visitar este <a href = "https://bit.ly/3h1udeo" target = "_blank">tweet</a> o en su defecto escribirnos a basketmetrics@gmail.com
                        </p>
                        <p>A continuación, tenéis un listado de colaboradores ordenados por orden alfabético dentro de cada categoría. </p>
                    </Col>
                </Row>
                <Row style = {{marginTop: "15" + "px"}}>
                    <Col md = {2}>
                        <div className = "font-weight-bold text-center"
                            style = {{backgroundColor: "#354560", color: "#FFFFFF", borderRadius: 0.5  + "em", border: "3px solid #091E36", 
                                    padding: 5 + "px", fontSize: 16 + "pt", marginTop: 10 + "px"}}>
                                    L.F. Endesa
                        </div> 
                    </Col>
                </Row>
                <Row style = {{marginTop: 5 + "px"}}>
                    <Col md = {4}>
                        <Row style = {{marginTop: 5 + "px"}}>
                            <Col md = {4}>
                                <div className = "text-center">
                                    <a href = "https://www.cbleganes.es" target = "_blank"><img src = {leganes} alt = "Club Baloncesto Leganés" /></a>
                                </div>                        
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row style = {{marginTop: "15" + "px"}}>
                    <Col md = {6}>
                        <div className = "font-weight-bold text-center"
                            style = {{backgroundColor: "#354560", color: "#FFFFFF", borderRadius: 0.5  + "em", border: "3px solid #091E36", 
                                    padding: 5 + "px", fontSize: 16 + "pt", marginTop: 10 + "px"}}>
                                    Liga Femenina Challenge
                        </div> 
                    </Col>
                    <Col md = {6}>
                        <div className = "font-weight-bold text-center"
                            style = {{backgroundColor: "#354560", color: "#FFFFFF", borderRadius: 0.5  + "em", border: "3px solid #091E36", 
                                    padding: 5 + "px", fontSize: 16 + "pt", marginTop: 10 + "px"}}>
                                    Liga Femenina 2
                        </div> 
                    </Col>
                </Row>
                <Row>
                    <Col md = {6}>
                        <Row style = {{marginTop: 5 + "px"}}>
                            <Col md = {4}>
                                <div className = "text-center">
                                    <a href = "https://www.ardoibaloncesto.com/" target = "_blank"><img src = {ardoi} alt = "Fundación Navarra Baloncesto Ardoi" /></a>
                                </div>                        
                            </Col>                            
                            <Col md = {4}>
                                <div className = "text-center">
                                    <a href = "https://cabestepona.es/" target = "_blank"><img src = {estepona} alt = "CAB Estepona" /></a>
                                </div>                        
                            </Col>
                            <Col md = {4}>
                                <div className = "text-center">
                                    <a href = "https://cdlasallemelilla.com/" target = "_blank"><img src = {melilla} alt = "Melilla Sport La Salle Capital" /></a>
                                </div>                        
                            </Col>
                        </Row>
                    </Col>
                    <Col md = {6}>
                        <Row style = {{marginTop: 5 + "px"}}>
                            <Col md = {4}>
                                <div className = "text-center">
                                    <a href = "https://twitter.com/barakaldo__est?lang=es" target = "_blank"><img src = {barakaldo} alt = "HGB AUSARTA BARAKALDO E.S.T." /></a>
                                </div>                        
                            </Col>
                            <Col md = {4}>
                                <div className = "text-center">
                                    <a href = "https://twitter.com/magectias1995?lang=es" target = "_blank"><img src = {magectias} alt = "Magec Tías Contra la violencia de género" /></a>
                                </div>                        
                            </Col>
                            <Col md = {4}>
                                <div className = "text-center">
                                    <a href = "#" target = "_blank"><img src = {logroño} alt = "UniBasket Logroño" /></a>
                                </div>                        
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>               
        )
    }
}

